import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Form, Input, PageHeader, Row, Select, Table} from "antd";
import UserService from "./userService";
import {CalendarOutlined, FileExcelOutlined, TagOutlined} from "@ant-design/icons";

const columns = [
    {
        title: 'Patient ID',
        key: 'patientId',
        fixed: 'left',
        dataIndex: 'patientId',
        width: 250,
        visible: true,
    },
    {
        title: 'Referentie',
        fixed: 'left',
        width: 150,
        visible: true,
        dataIndex: 'ownReference',
        key: 'ownReference',
    },
    {
        title: 'Naam vpk',
        visible: true,
        dataIndex: 'vpk',
        key: 'vpk',
        width: 200,
    },
    {
        title: 'Datum',
        dataIndex: 'date',
        key: 'date',
        width: 150,
    },
    {
        title: 'Tijdstip',
        dataIndex: 'time',
        key: 'time',
        width: 100,
    },
    {
        title: 'Uitvoering door',
        dataIndex: 'doneBy',
        key: 'doneBy',
        width: 150,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 200,
    },
    {
        title: 'Opmerking VPK',
        dataIndex: 'annotation_vpk',
        key: 'annotation_vpk',
        width: 250,
    },
    {
        title: 'Opmerking Desk',
        dataIndex: 'annotation_desk',
        key: 'annotation_desk',
        width: 250,
    },
];

const Appointments = (props) => {
    const [showForm, setShowForm] = useState(false);
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 10
    });
    const [dateFrom, setDateFrom] = useState(null);
    const [dateUntil, setDateUntil] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [downloading, setDownloading] = useState(false);

    const getRecords = async (page) => {
        await setLoading(true);
        const me = await UserService.getDecodedToken();

        let dateFromFormat = null;
        if (dateFrom) {
            dateFromFormat = dateFrom.format('YYYY-MM-DD');
        }

        let dateUntilFormat = null;
        if (dateUntil) {
            dateUntilFormat = dateUntil.format('YYYY-MM-DD');
        }

        const crequest = await UserService.authFetch("/v1/users/" + me.id + "/afspraken/all?count=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&search=" + searchQuery, {
            method: "get"
        });
        const cresponse = await crequest.json();

        await setPagination((pager) => {
            let newPager = pager;
            newPager.total = cresponse.numUvo;

            return newPager;
        });

        const request = await UserService.authFetch("/v1/users/" + me.id + "/afspraken/all?page=" + page + "&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&search=" + searchQuery, {
            method: "get"
        });
        const response = await request.json();
        await setRecords(response.items);

        setLoading(false);
    };

    useEffect(() => {
        getRecords(1);
    }, []);

    useEffect(() => {
        getRecords(1);
    }, [dateFrom, dateUntil, searchQuery]);

    const handleChange = (pagination, filters, sorter) => {
        getRecords(pagination.current)
    };

    const exportExcel = async () => {
        await setDownloading(true);
        const me = await UserService.getDecodedToken();

        let dateFromFormat = null;
        if (dateFrom) {
            dateFromFormat = dateFrom.format('YYYY-MM-DD');
        }

        let dateUntilFormat = null;
        if (dateUntil) {
            dateUntilFormat = dateUntil.format('YYYY-MM-DD');
        }

        return UserService.authFetch("/v1/users/" + me.id + "/afspraken/all?active=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&search=" + searchQuery + "&mode=download", {
            method: "GET",
        }).then(function(response){
            return response.blob();
        }).then(function(response){
            if (window.File && window.FileReader && window.FileList && window.Blob) {
                const reader = new window.FileReader();
                reader.readAsDataURL(response);
                reader.onload = function() {
                    let fileName = "Yulp-Export";

                    let dataUrl = reader.result;
                    let element = document.createElement("a");
                    element.setAttribute("href", dataUrl);
                    element.setAttribute("download", fileName + ".xlsx");

                    element.style.display = "none";
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                };
            }
            else {
                alert("Uw browser ondersteund deze functionaliteit niet...");
            }

            setDownloading(false);
        });
    };

    return (
        <>
            <PageHeader
                style={{
                    border: '1px solid rgb(235, 237, 240)',
                    marginBottom: 10
                }}
                title="Toekomstige afspraken"
                subTitle={"Overzicht van alle ingeplande afspraken vanaf vandaag"}
            />

            <Form layout="vertical">
                <Row gutter={[16,8]} style={{paddingTop: 20}}>
                    <Col sm={6}>
                        <Form.Item label={"Zoeken"}>
                            <Input.Search
                                placeholder="Zoeken"
                                onSearch={value => setSearchQuery(value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={"Datum vanaf"}>
                            <DatePicker value={dateFrom} onChange={(e) => setDateFrom(e)} format={"DD-MM-YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={"Datum t/m"}>
                            <DatePicker value={dateUntil} onChange={(e) => setDateUntil(e)} format={"DD-MM-YYYY"} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Row gutter={[16,8]}>
                <Col span={24}>
                    <Button icon={<FileExcelOutlined/>} style={{marginRight: 10}} onClick={exportExcel} loading={downloading}>Export Excel</Button>
                </Col>
            </Row>

            <Row gutter={[16,8]}>
                <Col span={24}>
                    <Table
                        scroll={{ x: 1000 }}
                        onChange={handleChange}
                        pagination={pagination}
                        loading={loading}
                        rowKey={'patientId'}
                        dataSource={records}
                        columns={columns} />
                </Col>
            </Row>
        </>
    );
};

export default Appointments;
