import React, {useEffect, useState} from "react";
import {Alert, Button, Card, Col, Descriptions, PageHeader, Row, Skeleton, Table, Tabs, Timeline} from 'antd';
import UserService from "./userService";
import moment from "moment";
import {
    CalendarTwoTone,
    CheckCircleTwoTone,
    HourglassTwoTone,
    PauseCircleTwoTone, StepBackwardOutlined,
    UserSwitchOutlined
} from "@ant-design/icons";
import ReportsListview from "./ReportsListview";

const clientLookupByBSN = (bsn, uvvId) => {
    let url = "/v1/client?bsn=" + bsn;

    if (uvvId) {
        url = "/v1/client?bsn=" + bsn + "&uvvId=" + uvvId
    }

    return UserService.authFetch(url, {
        method: "get"
    });
};

const CareRequest = (props) => {
    const [careRequest, setCareRequest] = useState({
        patient_id: '',
        dob: '',
        name: '',
        initials: '',
        method: '',
        diagnosis: '',
        medication: '',
        frequency_period: '',
        frequency_times: '',
        frequency_info: '',
        frequency_amount: '',
        frequency_unit: '',
        appointment_date_: '',
        delivery_date_: '',
        appointments: [],
        reports: [],
        dateReceivedShort: ""
    });

    const appointmentColumns = [
        {
            title: 'Datum afspraak',
            key: 'date',
            render: (index, record) => {
                let m = false;
                if (record.dateAppointment !== "Nog in te plannen") {
                    m = moment(record.dateAppointment, "DD-MM-YYYY HH:mm");
                }
                else {
                    return <span>Nog in te plannen</span>
                }

                return (
                    <>
                        <span>{m.format("dddd D MMMM") + " om " + m.format("HH:mm") + "u"}</span>
                        <br />
                        <small>{m && m.fromNow()}</small>
                    </>
                );
            }
        },
        {
            title: 'Organisatie',
            dataIndex: 'organisation',
            key: 'organisation',
        },
        {
            title: 'Status',
            key: 'status',
            render: (index, record) => {
                let icon = <span></span>;
                if (record.status === "Actief") {
                    icon = <CheckCircleTwoTone style={{marginRight: 5}} twoToneColor={"#71ff7d"} />;
                }
                if (record.status === "On hold") {
                    icon = <PauseCircleTwoTone style={{marginRight: 5}} twoToneColor={"#ff0000"} />;
                }
                if (record.status === "Nieuw") {
                    icon = <HourglassTwoTone style={{marginRight: 5}} twoToneColor={"#ffae00"} />;
                }
                if (record.status === "Toegewezen") {
                    icon = <UserSwitchOutlined style={{marginRight: 5, color: "#8fa6ff"}} />;
                }
                if (record.status === "Ingepland") {
                    icon = <CalendarTwoTone style={{marginRight: 5}} twoToneColor={"#8fa6ff"} />;
                }
                if (record.status === "Afgerond") {
                    icon = <CheckCircleTwoTone style={{marginRight: 5}} twoToneColor={"#71ff7d"} />;
                }

                return (
                    <>
                        {icon} <span>{record.status}</span>
                    </>
                )
            }
        },
        {
            title: 'Opmerking VPK',
            dataIndex: 'annotation_vpk',
            key: 'annotation_vpk',
        },
        {
            title: 'Opmerking Desk',
            dataIndex: 'annotation_desk',
            key: 'annotation_desk',
        },
    ];

    const reportColumns = [
        {
            title: 'Datum',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Tijd',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Datum volgende afspraak',
            dataIndex: 'dateNextAppointment',
            key: 'dateNextAppointment',
        },
        {
            title: 'Verslag',
            dataIndex: 'report',
            key: 'report',
        },
    ];

    const alertColumns = [
        {
            title: 'Datum',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Tijd',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Bijwerking',
            dataIndex: 'report',
            key: 'report',
        },
    ];

    useEffect(() => {
        async function getCareRequest() {
            const request = await clientLookupByBSN(props.careRequest.bsn, props.careRequest.key);
            const response = await request.json();

            console.log(response);

            setCareRequest(response);
        }

        getCareRequest();
    }, []);

    return (
        <>
            <PageHeader
                style={{
                    border: '1px solid rgb(235, 237, 240)',
                    marginBottom: 10
                }}
                title={careRequest.eigen_referentie && careRequest.eigen_referentie.length ? `${careRequest.patient_id} / ${careRequest.eigen_referentie}` : careRequest.patient_id}
                subTitle={"Detailpagina"}
                extra={[
                    <Button icon={<StepBackwardOutlined/>} key="1" type="primary" onClick={() => props.onClose()}>
                        Terug naar overzicht
                    </Button>,
                ]}
            />

            <Row gutter={[16,8]}>
                <Col span={6}>
                    <Row gutter={[16,8]}>
                        <Col>
                            <Card>
                                <h2>{careRequest.name}, {careRequest.initials}</h2>
                                <label>
                                    BSN
                                    <h4>{careRequest.bsn}</h4>
                                </label>
                                <label>
                                    Geboortedatum
                                    <h4>{careRequest.dob}</h4>
                                </label>
                                <label>
                                    Adres
                                    <h4>{careRequest.address} {careRequest.housenumber}</h4>
                                    <h4>{careRequest.zipcode} {careRequest.city}</h4>
                                </label>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{paddingTop: 20}}>
                                <Timeline>
                                    <Timeline.Item color="green"><strong>{careRequest.dateReceivedShort}</strong> Aanmelding verzoek</Timeline.Item>
                                </Timeline>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col span={18}>
                    <Tabs defaultActiveKey="1" animated={false}>
                        <Tabs.TabPane tab="Details verzoek" key="1">
                            <Col>
                                <Descriptions layout="vertical" bordered column={4}>
                                    <Descriptions.Item label="Diagnose" span={2}>{careRequest.diagnosis}</Descriptions.Item>
                                    <Descriptions.Item label="Medicatie" span={2}>{careRequest.medication}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions layout="vertical" bordered>
                                    <Descriptions.Item label="Toedieningsvorm">{careRequest.method}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions layout="vertical" bordered column={4}>
                                    <Descriptions.Item label="Frequentie">{careRequest.frequency_times}</Descriptions.Item>
                                    <Descriptions.Item label="Periode">{careRequest.frequency_period}</Descriptions.Item>
                                    <Descriptions.Item label="Hoeveelheid">{careRequest.frequency_amount}</Descriptions.Item>
                                    <Descriptions.Item label="Eenheid">{careRequest.frequency_unit}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions layout="vertical" bordered>
                                    <Descriptions.Item label="Extra informatie m.b.t. de dosering en/of frequentie">{careRequest.frequency_info}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions layout="vertical" bordered>
                                    <Descriptions.Item label="Leverdatum medicatie">{careRequest.delivery_date_}</Descriptions.Item>
                                    <Descriptions.Item label="Gewenste uitvoeringsdatum">{careRequest.appointment_date_}</Descriptions.Item>
                                    <Descriptions.Item label="Einddatum recept">-</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Afspraken" key="2">
                            <Table
                                columns={appointmentColumns}
                                dataSource={props.careRequest.appointments} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Verslagen" key="3">
                            <ReportsListview
                                reports={props.careRequest.reports}
                                loading={false}
                                showReport
                                showDetailHeader={false} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Meldingen" key="4">
                            <ReportsListview
                                reports={props.careRequest.reports.filter(r => r.adverse_event && r.adverse_event.length)}
                                showAdverseEvent
                                loading={false}
                                showDetailHeader={false} />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        </>
    );
};

export default CareRequest;
