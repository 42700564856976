import "whatwg-fetch";
import UserService from "./userService";

class RequestService {
    static clientLookupByBSN = (bsn, uvvId) => {
        let url = "/v1/client?bsn=" + bsn;

        if (uvvId) {
            url = "/v1/client?bsn=" + bsn + "&uvvId=" + uvvId
        }

        return UserService.authFetch(url, {
            method: "get"
        });
    };
}

export default RequestService;
