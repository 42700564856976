import React, {useEffect, useState} from "react";
import {Button, Card, Col, Descriptions, PageHeader, Row} from 'antd';
import RequestService from "./requestService";
import {StepBackwardOutlined} from "@ant-design/icons";

const Report = (props) => {
    const [careRequest, setCareRequest] = useState({
        patient_id: '',
        dob: '',
        name: '',
        initials: '',
        method: '',
        diagnosis: '',
        medication: '',
        frequency_period: '',
        frequency_times: '',
        frequency_info: '',
        frequency_amount: '',
        frequency_unit: '',
        appointment_date_: '',
        delivery_date_: '',
        appointments: [],
        reports: []
    });

    useEffect(() => {
        async function getCareRequest() {
            const request = await RequestService.clientLookupByBSN(props.report.bsn, props.report.uvv_id);
            const response = await request.json();

            setCareRequest(response);
        }

        getCareRequest();
    }, []);

    return (
        <>
            {props.showDetailHeader && <PageHeader
                style={{
                    border: '1px solid rgb(235, 237, 240)',
                    marginBottom: 10
                }}
                title={"Verslag van " + props.report.date + " voor patient " + careRequest.patient_id}
                subTitle={"Detailpagina"}
                extra={[
                    <Button icon={<StepBackwardOutlined/>} key="1" type="primary" onClick={() => props.onClose()}>
                        Terug naar overzicht
                    </Button>,
                ]}
            />}

            <Row gutter={[16,8]}>
                <Col span={6}>
                    <Row gutter={[16,8]}>
                        <Col>
                            <Card>
                                <h2>{careRequest.name}, {careRequest.initials}</h2>
                                <h3>{careRequest.dob}</h3>
                                <h3>{careRequest.city}</h3>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col span={18}>
                    <Descriptions layout="vertical" bordered column={4}>
                        <Descriptions.Item label="Datum" span={2}>{props.report.date}</Descriptions.Item>
                        <Descriptions.Item label="Tijdstip" span={2}>{props.report.time}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions layout="vertical" bordered column={4}>
                        <Descriptions.Item label="Contactvorm" span={2}>{props.report.contactType}</Descriptions.Item>
                        <Descriptions.Item label="Zorgprogramma stopt?" span={2}>{props.report.zorgStopt ? "Ja" : "Nee"}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions layout="vertical" bordered>
                        <Descriptions.Item label="Verslag">{props.report.report}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions layout="vertical" bordered>
                        <Descriptions.Item label="Interne opmerking">{props.report.notes_private ? props.report.notes_private : "-"}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions layout="vertical" bordered>
                        <Descriptions.Item label="Bijwerkingen">{props.report.adverse_event ? props.report.adverse_event : "-"}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions layout="vertical" bordered column={4}>
                        <Descriptions.Item label="Datum volgende afspraak" span={2}>{props.report.dateAppointment.substring(0, 10)}</Descriptions.Item>
                        <Descriptions.Item label="Tijdstip volgende afspraak" span={2}>{props.report.timeAppointment ? props.report.timeAppointment.substring(0,5) : "Onbekend"}</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        </>
    );
};

export default Report;
