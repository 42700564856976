import React, {Suspense, useEffect, useState} from 'react';
import {Alert, Button, Form, Input, InputNumber, Spin} from 'antd';
import 'antd/dist/antd.css';
import './App.css';
import Panel from "./Panel";
import UserService from "./userService";
import {Trans} from 'react-i18next';
import './i18n';
import {CodeOutlined, LoadingOutlined, LoginOutlined} from "@ant-design/icons";

const App = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);
    const [passedSecondFactor, setPassedSecondFactor] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const handleSubmit = async () => {
        await setError(undefined);
        await setLoading(true);
        const authResult = await UserService.authenticate(username, password, "", false);

        if (authResult && authResult['id']) {
            if (!authResult['needs_2fa']) {
                await UserService.authenticate(username, password, "", true);
                await setPassedSecondFactor(true);
            }

            await setLoggedIn(true);
        }
        else {
            setError("Het inloggen is mislukt omdat de combinatie van uw gebruikersnaam en wachtwoord onjuist is.");
        }

        await setLoading(false);
    };

    const handleSubmitMultiFactor = async () => {
        await setOtpError("");

        if (otp && otp.toString().length === 6) {
            const authResult = await UserService.authenticate(username, password, otp, true);

            if (authResult && authResult['id'] && (authResult['passed_2fa'] || !authResult['needs_2fa'])) {
                await setPassedSecondFactor(true);
            }
            else {
                await setOtpError("De ingevoerde code is onjuist");
            }
        }
        else {
            await setOtpError("De ingevoerde code is onjuist");
        }

        await setLoading(false);
    };

    useEffect(() => {
        if (UserService.isLoggedIn()) {
            setLoggedIn(true);
        }
        if (UserService.isMultiAuth()) {
            setPassedSecondFactor(true);
        }
    }, []);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className="App">
                {loggedIn && passedSecondFactor && <Panel />}

                {!loggedIn && <header
                    className={"App-header bg-" + UserService.getCustomer().toLowerCase()}
                >
                    <LoginForm
                        username={username}
                        password={password}
                        setUsername={setUsername}
                        setPassword={setPassword}
                        handleSubmit={handleSubmit}
                        loading={loading}
                        error={error} />
                </header>}

                {loggedIn && !passedSecondFactor && <header className={"App-header bg-" + UserService.getCustomer().toLowerCase()}>
                    <MultiFactorForm
                        otp={otp}
                        setOtp={setOtp}
                        otpError={otpError}
                        handleSubmit={handleSubmitMultiFactor}
                        loading={loading}
                        error={error} />
                </header>}
            </div>
        </Suspense>
  );
};

const LoginForm = (props) => {
    const [customer, setCustomer] = useState("");

    useEffect(() => {
        setCustomer(UserService.getCustomer());
    }, []);

    return (
        <>
            <div className={"login-form-wrapper"} style={{marginBottom: 5, padding: 10, opacity: 1}}>
                <img src={"logo-" + UserService.getCustomer().toLowerCase() + ".png"} className="App-logo" alt="logo" />
            </div>
            <div className={"login-form-wrapper"}>
                <Trans i18nKey={"loginTitle"}>
                    <h5 style={{color: "#fff"}}>Sign in to the {{customer}} portal!</h5>
                </Trans>
                <Form onFinish={props.handleSubmit} className="login-form">
                    <Form.Item>
                        <Input
                            placeholder="Gebruikersnaam"
                            onChange={e => props.setUsername(e.target.value)}
                            value={props.username}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Input
                            type="password"
                            placeholder="Wachtwoord"
                            onChange={e => props.setPassword(e.target.value)}
                            value={props.password}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button" style={{width: "100%"}} disabled={!props.username.length || !props.password.length}>
                            Sign in
                        </Button>
                    </Form.Item>

                    {props.loading && <Spin />}

                    {props.error && <Alert
                        message="Inloggen mislukt"
                        description={props.error}
                        type="error"
                    />}
                </Form>
            </div>
        </>
    );
};

const MultiFactorForm = (props) => {
    return (
        <>
            <div className={"login-form-wrapper"} style={{marginBottom: 5, padding: 10, opacity: 1}}>
                <img src={"logo-" + UserService.getCustomer().toLowerCase() + ".png"} className="App-logo" alt="logo" />
            </div>
            <div className={"login-form-wrapper"}>
                <h5 style={{color: "#fff"}}>Login op het klantenportaal van {UserService.getCustomer()}!</h5>
                <Form onFinish={props.handleSubmit} className="login-form">
                    <p style={{color: "#fff", width: "100%"}}>Vul de 6-cijferige code in van de token.</p>
                    <Form.Item>
                        <Input style={{width: 200}} size={"large"} maxLength={6} minLength={6} onChange={e => props.setOtp(e.target.value)} value={props.otp}
                            prefix={<CodeOutlined />}
                            placeholder="Code"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button" style={{width: "100%"}}>
                            <LoginOutlined /> Code controleren
                        </Button>
                    </Form.Item>

                    {props.loading && <LoadingOutlined />}

                    {props.otpError && <Alert
                        message="Inloggen mislukt"
                        description={props.otpError}
                        type="error"
                    />}
                </Form>
            </div>
        </>
    );
};

export default App;
