import React, {useEffect, useState} from "react";
import {Card, Col, Divider, Icon, PageHeader, Row, Statistic} from "antd";
import UserService from "./userService";

const Dashboard = (props) => {
    const [numRequests, setNumRequests] = useState(0);
    const [numRequestsProjects, setNumRequestsProjects] = useState([]);
    const [numRequestsMedication, setNumRequestsMedication] = useState([]);
    const [numAppointments, setNumAppointments] = useState(0);
    const [numAppointmentsProjects, setNumAppointmentsProjects] = useState([]);
    const [numOnholds, setNumOnholds] = useState(0);
    const [numOnholdsProjects, setNumOnholdsProjects] = useState([]);

    useEffect(() => {
        async function fetchRequests() {
            const me = await UserService.getDecodedToken();
            const request = await UserService.authFetch("/v1/users/" + me.id + "/uitvoeringsverzoeken/all?count=1", {
                method: "get"
            });
            const response = await request.json();
            await setNumRequestsProjects(response.projects);
            await setNumRequestsMedication(response.medication);
            await setNumRequests(response.numUvv);
        }

        async function fetchAppointments() {
            const me = await UserService.getDecodedToken();
            const request = await UserService.authFetch("/v1/users/" + me.id + "/afspraken/all?count=1", {
                method: "get"
            });
            const response = await request.json();
            await setNumAppointmentsProjects(response.projects);
            await setNumAppointments(response.numUvo);
        }

        async function fetchOnholds() {
            const me = await UserService.getDecodedToken();
            const request = await UserService.authFetch("/v1/users/" + me.id + "/uitvoeringsverzoeken/all?count=1&active=1&onhold=1", {
                method: "get"
            });
            const response = await request.json();
            await setNumOnholdsProjects(response.projects);
            await setNumOnholds(response.numUvv);
        }

        fetchRequests();
        fetchAppointments();
        fetchOnholds();
    }, []);

    return (
        <>
            <PageHeader
                style={{
                    border: '1px solid rgb(235, 237, 240)',
                    marginBottom: 10
                }}
                title="Dashboard"
                subTitle={"Welkom op het " + UserService.getCustomer() + "! platform"}
            />

            <Row gutter={16}>
                <Col sm={24} md={6}>
                    <Card style={{textAlign: "center"}}>
                        {numRequestsMedication.sort((a,b) => {
                            if (a.numUvv > b.numUvv) {
                                return -1;
                            }
                            if (a.numUvv < b.numUvv) {
                                return 1;
                            }
                            return 0;
                        }).map((num, index) => (
                            <div key={index}>
                                <Row justify="space-between" align="middle">
                                    <Col sm={12}>
                                        <Statistic decimalSeparator={","} groupSeparator={"."} precision={0} title={num.medication} value={num.numUvv} />
                                    </Col>
                                    <Col sm={12}>
                                        <Statistic decimalSeparator={","} groupSeparator={"."} precision={2} title="Aandeel" value={num.numUvv / numRequests * 100} suffix="%" />
                                    </Col>
                                </Row>
                                <Divider />
                            </div>
                        ))}
                    </Card>
                </Col>
                <Col sm={24} md={6}>
                    <Card style={{textAlign: "center"}}>
                        <Statistic decimalSeparator={","} groupSeparator={"."} precision={0} title="Uitvoeringsverzoeken" value={numRequests} />

                        {numRequestsProjects.map((num, index) => (
                            <div key={index}>
                                <Divider />
                                <Row justify="space-between" align="middle">
                                    <Col sm={12}>
                                        <Statistic decimalSeparator={","} groupSeparator={"."} precision={0} title={num.project} value={num.numUvv} />
                                    </Col>
                                    <Col sm={12}>
                                        <Statistic decimalSeparator={","} groupSeparator={"."} precision={2} title="Aandeel" value={num.numUvv / numRequests * 100} suffix="%" />
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </Card>
                </Col>
                <Col sm={24} md={6}>
                    <Card style={{textAlign: "center"}}>
                        <Statistic decimalSeparator={","} groupSeparator={"."} precision={0} title="Afspraken" value={numAppointments} />

                        {numAppointmentsProjects.map((num, index) => (
                            <div key={index}>
                                <Divider />
                                <Row justify="space-between" align="middle">
                                    <Col sm={12}>
                                        <Statistic decimalSeparator={","} groupSeparator={"."} precision={0} title={num.project} value={num.numUvo} />
                                    </Col>
                                    <Col sm={12}>
                                        <Statistic decimalSeparator={","} groupSeparator={"."} precision={2} title="Aandeel" value={num.numUvo / numAppointments * 100} suffix="%" />
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </Card>
                </Col>
                <Col sm={24} md={6}>
                    <Card style={{textAlign: "center"}}>
                        <Statistic decimalSeparator={","} groupSeparator={"."} precision={0} title="On holds" value={numOnholds} />

                        {numOnholdsProjects.map((num, index) => (
                            <div key={index}>
                                <Divider />
                                <Row justify="space-between" align="middle">
                                    <Col sm={12}>
                                        <Statistic decimalSeparator={","} groupSeparator={"."} precision={0} title={num.project} value={num.numUvv} />
                                    </Col>
                                    <Col sm={12}>
                                        <Statistic decimalSeparator={","} groupSeparator={"."} precision={2} title="Aandeel" value={num.numUvv / numOnholds * 100} suffix="%" />
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default Dashboard;
