import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Divider, Form, Icon, Input, Modal, PageHeader, Row, Select, Table, Tabs, DatePicker, Pagination} from 'antd';
import {
    CalendarTwoTone,
    CheckCircleTwoTone, FileExcelOutlined,
    HourglassTwoTone,
    PauseCircleTwoTone,
    UserSwitchOutlined
} from '@ant-design/icons';
import CareRequestForm from "./CareRequestForm";
import UserService from "./userService";
import CareRequest from "./CareRequest";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import {useTranslation} from 'react-i18next';
import 'moment/locale/nl';
import {debounce} from "lodash"

moment.locale("nl");

const SalesReport = (props) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [editRecord, setEditRecord] = useState(undefined);
    const [renewRecord, setRenewRecord] = useState(undefined);
    const [careRequestsActive, setCareRequestsActive] = useState([]);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateUntil, setDateUntil] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCareRequest, setSelectedCareRequest] = useState(undefined);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [numRequestsActive, setNumRequestsActive] = useState(0);
    const [paginationActive, setPaginationActive] = useState({
        pageSize: 10
    });
    const [downloading, setDownloading] = useState(false);

    const handleChangeActive = (pagination, filters, sorter) => {
        getCareRequests(pagination.current)
    };

    const getCareRequests = async (page) => {
        if (page === undefined) {
            page = 1;
        }

        await setLoading(true);
        const me = await UserService.getDecodedToken();

        let dateFromFormat = null;
        if (dateFrom) {
            dateFromFormat = dateFrom.format('YYYY-MM-DD');
        }

        let dateUntilFormat = null;
        if (dateUntil) {
            dateUntilFormat = dateUntil.format('YYYY-MM-DD');
        }

        const requestNumActive = await UserService.authFetch("/v1/users/" + me.id + "/teva-report/all?count=1&active=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&search=" + searchQuery, {
            method: "get"
        });
        const responseNumActive = await requestNumActive.json();
        await setNumRequestsActive(responseNumActive.numUvv);
        await setPaginationActive((pager) => {
            let newPager = pager;
            newPager.total = responseNumActive.numUvv;

            return newPager;
        });

        const request = await UserService.authFetch("/v1/users/" + me.id + "/teva-report/all?active=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&page=" + page + "&search=" + searchQuery, {
            method: "get"
        });
        const response = await request.json();
        await setCareRequestsActive(response.items);
        await setLoading(false);
    };

    useEffect(() => {
        getCareRequests(1);
    }, [dateFrom, dateUntil, searchQuery]);

    const exportExcel = async () => {
        await setDownloading(true);
        const me = await UserService.getDecodedToken();

        let dateFromFormat = null;
        if (dateFrom) {
            dateFromFormat = dateFrom.format('YYYY-MM-DD');
        }

        let dateUntilFormat = null;
        if (dateUntil) {
            dateUntilFormat = dateUntil.format('YYYY-MM-DD');
        }

        return UserService.authFetch("/v1/users/" + me.id + "/teva-report/all?active=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&search=" + searchQuery + "&mode=download", {
            method: "GET",
        }).then(function(response){
            return response.blob();
        }).then(function(response){
            if (window.File && window.FileReader && window.FileList && window.Blob) {
                const reader = new window.FileReader();
                reader.readAsDataURL(response);
                reader.onload = function() {
                    let fileName = "Yulp-Export";

                    let dataUrl = reader.result;
                    let element = document.createElement("a");
                    element.setAttribute("href", dataUrl);
                    element.setAttribute("download", fileName + ".xlsx");

                    element.style.display = "none";
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                };
            }
            else {
                alert("Uw browser ondersteund deze functionaliteit niet...");
            }

            setDownloading(false);
        });
    };

    const downloadPdf = async (record) => {
        const url = "/v1/client?uvvId=" + record.id + "&recept=1";

        return UserService.authFetch(url, {
            method: "GET",
        }).then(function(response){
            return response.blob();
        }).then(function(response){
            if (window.File && window.FileReader && window.FileList && window.Blob) {
                const reader = new window.FileReader();
                reader.readAsDataURL(response);
                reader.onload = function() {
                    let fileName = "YULP_RECEPT_" + record.patientId;

                    let dataUrl = reader.result;
                    let element = document.createElement("a");
                    element.setAttribute("href", dataUrl);
                    element.setAttribute("download", fileName + ".pdf");

                    element.style.display = "none";
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                };
            }
            else {
                alert("Uw browser ondersteund deze functionaliteit niet...");
            }
        });
    }

    const columns = [
        {
            title: "Aanmelddatum",
            dataIndex: 'dateReceived',
            key: 'dateReceived',
        },
        {
            title: "Bezoekdatum",
            key: 'dateAppointment',
            render: (index, record) => {
                let m = false;
                if (record.dateAppointment !== "Nog in te plannen") {
                    m = record.rowType === "uvv" ? moment(record.dateAppointment, "DD-MM-YYYY") : moment(record.dateAppointment, "DD-MM-YYYY HH:mm");
                }

                if (record.rowType === "uvv") {
                    return (
                        <>
                            <span>{record.rowType === "uvv" ? record.dateAppointment + " (voorkeur)" : t("waitingForAppointment")}</span>
                            <br />
                            <small>{m && m.fromNow()}</small>
                        </>
                    )
                }
                else {
                    if (record.dateAppointment === "Nog in te plannen") {
                        return "Nog in te plannen";
                    }
                    return (
                        <>
                            <span>{m.format("dd DD-MM-YYYY")}</span>
                            <br />
                            <small>{m && m.fromNow()}</small>
                        </>
                    );
                }
            }
        },
        {
            title: "Ziekenhuis",
            dataIndex: 'ziekenhuis',
            key: 'hospital',
        },
        {
            title: "Handeling uitvoeringsverzoek",
            dataIndex: 'handeling_request',
            key: 'procedure_request',
        },
        {
            title: "Handeling uitvoeringsverzoek",
            dataIndex: 'handeling_reported',
            key: 'procedure_reported',
        },
        {
            title: "Product",
            dataIndex: 'middel',
            key: 'medication',
        },
    ];

    if (selectedCareRequest) {
        return (<CareRequest
            onClose={() => setSelectedCareRequest(undefined)}
            careRequest={selectedCareRequest} />);
    }

    return (
        <>
            <PageHeader
                style={{
                    border: '1px solid rgb(235, 237, 240)',
                    marginBottom: 10
                }}
                title="Rapportage TEVA"
                subTitle={t("overview")}
            />

            <Row gutter={[16,8]} style={{paddingTop: 20}}>
                <Col span={6}>
                    <Form.Item label={"Zoeken"}>
                        <Input.Search
                            placeholder="Zoeken"
                            onSearch={value => setSearchQuery(value)}
                            style={{ width: 300 }}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item label={"Datum vanaf"}>
                        <DatePicker value={dateFrom} onChange={(e) => setDateFrom(e)} format={"DD-MM-YYYY"} />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item label={"Datum t/m"}>
                        <DatePicker value={dateUntil} onChange={(e) => setDateUntil(e)} format={"DD-MM-YYYY"} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16,8]}>
                <Col span={24}>
                    <Button icon={<FileExcelOutlined/>} style={{marginRight: 10}} onClick={exportExcel} loading={downloading}>Export Excel</Button>
                </Col>
            </Row>

            <Row gutter={[16,8]}>
                <Col span={24}>
                    <Table
                        pagination={paginationActive}
                        onChange={handleChangeActive}
                        loading={loading}
                        className={"custom-table"}
                        expandedRowKeys={expandedRowKeys}
                        onExpand={(expanded, record) => setExpandedRowKeys(expanded ? [record.key] : [])}
                        childrenColumnName={"appointments"}
                        rowClassName={(record) => {
                            if (record.rowType === "uvo") {
                                return "child-row-level-1";
                            }
                            return "parent-row";
                        }}
                        dataSource={careRequestsActive}
                        columns={columns} />
                </Col>
            </Row>
        </>
    );
};

export default SalesReport;

