import React, {useEffect, useState} from "react";
import {Button, PageHeader, Table} from "antd";
import UserService from "./userService";
import Report from "./Report";
import {CalendarOutlined, TagOutlined} from "@ant-design/icons";

const ReportsListview = (props) => {
    const [selectedReport, setSelectedReport] = useState(undefined);

    const columns = [
        {
            title: 'Patient ID',
            key: 'patientId',
            fixed: 'left',
            width: 250,
            visible: true,
            render: (index, record) => {
                if (record.rowType === "uvo") { // Afspraak, child row
                    return <span><CalendarOutlined /> {record.patientId}</span>
                }
                return (
                    <Button icon={<TagOutlined />} type={"secondary"} onClick={() => setSelectedReport(record)}>{record.patientId}</Button>
                );
            }
        },
        {
            title: 'Referentie',
            fixed: 'left',
            width: 150,
            visible: true,
            dataIndex: 'ownReference',
            key: 'ownReference',
        },
        {
            title: 'Middel',
            fixed: 'left',
            width: 150,
            visible: true,
            dataIndex: 'uvv_middel',
            key: 'uvv_middel',
        },
        {
            title: 'Naam vpk',
            visible: true,
            dataIndex: 'vpk',
            key: 'vpk',
        },
        {
            visible: true,
            title: 'Datum',
            dataIndex: 'date',
            key: 'date',
        },
        {
            visible: true,
            title: 'Tijd',
            dataIndex: 'time',
            key: 'time',
        },
        {
            visible: true,
            title: 'Datum volgende afspraak',
            dataIndex: 'dateAppointment',
            key: 'dateAppointment',
        },
        {
            visible: props.showReport,
            title: 'Verslag',
            dataIndex: 'report',
            key: 'report',
            width: "250px",
        },
        {
            visible: props.showAdverseEvent,
            title: 'Bijwerking',
            dataIndex: 'adverse_event',
            width: "250px",
        }
    ];

    if (selectedReport) {
        return <Report
            showDetailHeader={props.showDetailHeader}
            onClose={() => setSelectedReport(undefined)}
            report={selectedReport} />
    }

    return (
        <>
            <Table
                scroll={{ x: 1000 }}
                pagination={props.pagination}
                onChange={props.onChange}
                loading={props.loading}
                dataSource={props.reports}
                columns={columns.filter(c => c.visible)} />
        </>
    );
};

export default ReportsListview;
