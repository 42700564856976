import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, PageHeader, Row, Table} from "antd";
import UserService from "./userService";
import Password from "antd/es/input/Password";

const columns = [
    {
        title: 'Gebruikersnaam',
        dataIndex: 'username',
        key: 'username',
    },
    {
        title: 'Actief sinds',
        dataIndex: 'active',
        key: 'active',
    },
    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role'
    },
    {
        title: 'Functie',
        dataIndex: 'profession',
        key: 'profession',
    },
    {
        title: 'Laatste login',
        dataIndex: 'lastLogin',
        key: 'lastLogin',
    },
];

const Settings = (props) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const token = UserService.getDecodedToken();

        setUsers((curUsers) => curUsers.concat({username: token.username, role: token.role === 'Primary' ? 'Hoofdaccount' : 'Subaccount'}));
    }, []);

    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <PageHeader
                        style={{
                            border: '1px solid rgb(235, 237, 240)',
                            marginBottom: 10
                        }}
                        title="Personen"
                        subTitle={"binnen mijn omgeving"}
                    />

                    <Table
                        rowKey={"username"}
                        dataSource={users} columns={columns} />
                </Col>
                <Col span={12}>
                    <PageHeader
                        style={{
                            border: '1px solid rgb(235, 237, 240)',
                            marginBottom: 10
                        }}
                        title="Mijn profiel"
                        subTitle={"wijzig je eigen profiel"}
                    />

                    <ChangePasswordFormWrapper/>
                </Col>
            </Row>
        </>
    );
};

const ChangePasswordForm = (props) => {
    const [form] = Form.useForm();

    const handleChangePasswordSubmit = async (values) => {
        const me = await UserService.getDecodedToken();
        const request = await UserService.authFetch("/v1/users/" + me.id + "/portal-password", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(values)
        });
        const response = await request.json();

        window.alert('Het wachtwoord is gewijzigd');
    };

    return (
        <Card>
            <Form layout="vertical" autoComplete={"off"} onFinish={handleChangePasswordSubmit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <h3>Wachtwoord wijzigen</h3>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Huidig wachtwoord" name="current_password" rules={[{ required: true, message: 'Dit is een verplicht veld' }]}>
                            <Password size={"large"} placeholder="" autoComplete={"none"} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Nieuw wachtwoord" name="new_password" rules={[
                            { required: true, message: 'Dit is een verplicht veld' },
                            { min: 8, message: 'Het wachtwoord dient minimaal 8 karakters lang te zijn'}
                        ]}>
                            <Password size={"large"} placeholder="" autoComplete={"new-password"} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Nieuw wachtwoord (controle)" name="new_password_check" rules={[
                            { required: true, message: 'Dit is een verplicht veld' },
                        ]}>
                            <Password size={"large"} placeholder="" autoComplete={"new-password"} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <Button type="primary" htmlType="submit">
                            Wachtwoord wijzigen
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};
const ChangePasswordFormWrapper = ChangePasswordForm;

export default Settings;
