import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Divider, Form, Input, Modal, PageHeader, Row, Select, Table, Tabs, DatePicker, Pagination} from 'antd';
import {
    CalendarOutlined,
    CalendarTwoTone,
    CheckCircleTwoTone, FileExcelOutlined, FilePdfOutlined,
    HourglassTwoTone,
    PauseCircleTwoTone, PlusCircleOutlined, TagOutlined,
    UserSwitchOutlined
} from '@ant-design/icons';
import CareRequestForm from "./CareRequestForm";
import UserService from "./userService";
import CareRequest from "./CareRequest";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import {useTranslation} from 'react-i18next';
import 'moment/locale/nl';
import {debounce} from "lodash"

moment.locale("nl");

const AdverseEvents = (props) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [editRecord, setEditRecord] = useState(undefined);
    const [renewRecord, setRenewRecord] = useState(undefined);
    const [careRequestsActive, setCareRequestsActive] = useState([]);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateUntil, setDateUntil] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCareRequest, setSelectedCareRequest] = useState(undefined);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [numRequestsActive, setNumRequestsActive] = useState(0);
    const [paginationActive, setPaginationActive] = useState({
        pageSize: 10
    });
    const [downloading, setDownloading] = useState(false);

    const handleChangeActive = (pagination, filters, sorter) => {
        getCareRequests(pagination.current)
    };

    const getCareRequests = async (page) => {
        if (page === undefined) {
            page = 1;
        }

        await setLoading(true);
        const me = await UserService.getDecodedToken();

        let dateFromFormat = null;
        if (dateFrom) {
            dateFromFormat = dateFrom.format('YYYY-MM-DD');
        }

        let dateUntilFormat = null;
        if (dateUntil) {
            dateUntilFormat = dateUntil.format('YYYY-MM-DD');
        }

        const requestNumActive = await UserService.authFetch("/v1/users/" + me.id + "/ae/all?count=1&active=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&search=" + searchQuery, {
            method: "get"
        });
        const responseNumActive = await requestNumActive.json();
        await setNumRequestsActive(responseNumActive.numUvv);
        await setPaginationActive((pager) => {
            let newPager = pager;
            newPager.total = responseNumActive.numUvv;

            return newPager;
        });

        const request = await UserService.authFetch("/v1/users/" + me.id + "/ae/all?active=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&page=" + page + "&search=" + searchQuery, {
            method: "get"
        });
        const response = await request.json();
        await setCareRequestsActive(response.items);
        await setLoading(false);
    };

    useEffect(() => {
        getCareRequests(1);
    }, [dateFrom, dateUntil, searchQuery]);

    const exportExcel = async () => {
        await setDownloading(true);
        const me = await UserService.getDecodedToken();

        let dateFromFormat = null;
        if (dateFrom) {
            dateFromFormat = dateFrom.format('YYYY-MM-DD');
        }

        let dateUntilFormat = null;
        if (dateUntil) {
            dateUntilFormat = dateUntil.format('YYYY-MM-DD');
        }

        return UserService.authFetch("/v1/users/" + me.id + "/ae/all?dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&search=" + searchQuery + "&mode=download", {
            method: "GET",
        }).then(function(response){
            return response.blob();
        }).then(function(response){
            if (window.File && window.FileReader && window.FileList && window.Blob) {
                const reader = new window.FileReader();
                reader.readAsDataURL(response);
                reader.onload = function() {
                    let fileName = "Yulp-Export";

                    let dataUrl = reader.result;
                    let element = document.createElement("a");
                    element.setAttribute("href", dataUrl);
                    element.setAttribute("download", fileName + ".xlsx");

                    element.style.display = "none";
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                };
            }
            else {
                alert("Uw browser ondersteund deze functionaliteit niet...");
            }

            setDownloading(false);
        });
    };

    const columns = [
        {
            title: 'Patient ID',
            fixed: 'left',
            width: 150,
            key: 'uvv_patient_id',
            dataIndex: "uvv_patient_id",
        },
        {
            title: 'Referentie',
            fixed: 'left',
            width: 150,
            dataIndex: 'uvv_referentie_opdrachtgever',
            key: 'uvv_referentie_opdrachtgever',
        },
        {
            title: 'Datum',
            key: 'timestamp',
            render: (text, row) => {
                return moment(row.timestamp).format('DD-MM-YYYY HH:SS');
            }
        },
        {
            title: 'Meldnummer',
            dataIndex: 'uvom_meldnummer',
            key: 'uvom_meldnummer',
        },
        {
            title: 'Type melding',
            dataIndex: 'uvom_soort',
            key: 'uvom_soort',
        },
        {
            title: 'Leeftijd pat.',
            dataIndex: 'patleeftijd',
            key: 'patleeftijd',
        },
        {
            title: 'Geslacht pat.',
            dataIndex: 'patgeslacht',
            key: 'patgeslacht',
        },
        {
            title: 'Middel',
            dataIndex: 'uvv_middel',
            key: 'uvv_middel',
        },
        {
            title: 'Omschrijving bijwerking',
            dataIndex: 'uvom_omschrijving',
            key: 'uvom_omschrijving',
            width: 300,
        },
        {
            title: 'Datum AE vernomen',
            dataIndex: 'uvom_datum_first_known',
            key: 'uvom_datum_first_known',
        },
        {
            title: 'Datum bijwerking start',
            dataIndex: 'uvom_datum_start',
            key: 'uvom_datum_start',
        },
        {
            title: 'Datum bijwerking eind',
            dataIndex: 'uvom_datum_eind',
            key: 'uvom_datum_eind',
        },
        {
            title: 'Datum bijwerking start',
            dataIndex: 'uvom_tijdstip_eind',
            key: 'uvom_tijdstip_eind',
        },
        {
            title: 'Behandeling',
            dataIndex: 'uvom_behandeling',
            key: 'uvom_behandeling',
        },
        {
            title: 'Onderzoek',
            dataIndex: 'uvom_onderzoek',
            key: 'uvom_onderzoek',
        },
        {
            title: 'Ernstige situatie',
            dataIndex: 'uvom_ernstige_situatie',
            key: 'uvom_ernstige_situatie',
        },
        {
            title: 'Persoonlijk waargenomen',
            dataIndex: 'uvom_persoonlijk_waargenomen',
            key: 'uvom_persoonlijk_waargenomen',
        },
        {
            title: 'Uitkomst bijwerking',
            dataIndex: 'uvom_uitkomst_bijwerking',
            key: 'uvom_uitkomst_bijwerking',
        },
        {
            title: 'Vermoeden relatie verdacht middel',
            dataIndex: 'uvom_vermoeden_relatie_verdacht_middel',
            key: 'uvom_vermoeden_relatie_verdacht_middel',
        },
        {
            title: 'Actie specialist',
            dataIndex: 'uvom_actie_specialist',
            key: 'uvom_actie_specialist',
        },
        {
            title: 'Batch nr.',
            dataIndex: 'uvom_batch_nummer',
            key: 'uvom_batch_nummer',
        },
        {
            title: 'Medicatie houdbaarheid',
            dataIndex: 'uvom_houdbaarheidsdatum',
            key: 'uvom_houdbaarheidsdatum',
        },
        {
            title: 'Patient nu zwanger',
            dataIndex: 'uvom_patient_zwanger',
            key: 'uvom_patient_zwanger',
        },
        {
            title: 'Laatste menstruatie',
            dataIndex: 'uvom_patient_laatste_menstruatie',
            key: 'uvom_patient_laatste_menstruatie',
        },
        {
            title: 'A-terme',
            dataIndex: 'uvom_patient_aterme',
            key: 'uvom_patient_aterme',
        },
        {
            title: 'Uitkomst zwangerschap',
            dataIndex: 'uvom_patient_zwangerschapsuitkomst',
            key: 'uvom_patient_zwangerschapsuitkomst',
        },
        {
            title: '',
            dataIndex: '',
            key: '',
        },
        {
            title: '',
            dataIndex: '',
            key: '',
        },
        {
            title: '',
            dataIndex: '',
            key: '',
        },
        {
            title: '',
            dataIndex: '',
            key: '',
        },
    ];

    if (selectedCareRequest) {
        return (<CareRequest
            onClose={() => setSelectedCareRequest(undefined)}
            careRequest={selectedCareRequest} />);
    }

    return (
        <>
            {showForm && <CareRequestForm
                editRecord={editRecord}
                renewRecord={renewRecord}
                onClose={() => {
                    setShowForm(false);
                    setEditRecord(undefined);
                    setRenewRecord(undefined);
                    getCareRequests();
                }}
            />}

            <PageHeader
                style={{
                    border: '1px solid rgb(235, 237, 240)',
                    marginBottom: 10
                }}
                title="Meldingen"
                subTitle={t("overview")}
            />

            <Form layout="vertical">
                <Row gutter={[16,8]} style={{paddingTop: 20}}>
                    <Col sm={6}>
                        <Form.Item label={"Zoeken"}>
                            <Input.Search
                                placeholder="Zoeken"
                                onSearch={value => setSearchQuery(value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={"Datum vanaf"}>
                            <DatePicker value={dateFrom} onChange={(e) => setDateFrom(e)} format={"DD-MM-YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={"Datum t/m"}>
                            <DatePicker value={dateUntil} onChange={(e) => setDateUntil(e)} format={"DD-MM-YYYY"} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Row gutter={[16,8]}>
                <Col span={24}>
                    <Button icon={<FileExcelOutlined/>} style={{marginRight: 10}} onClick={exportExcel} loading={downloading}>Export Excel</Button>
                </Col>
            </Row>

            <Row gutter={[16,8]}>
                <Col span={24}>
                    <Table
                        scroll={{ x: 4000 }}
                        pagination={paginationActive}
                        onChange={handleChangeActive}
                        loading={loading}
                        className={"custom-table"}
                        expandedRowKeys={expandedRowKeys}
                        onExpand={(expanded, record) => setExpandedRowKeys(expanded ? [record.key] : [])}
                        childrenColumnName={"appointments"}
                        rowClassName={(record) => {
                            if (record.rowType === "uvo") {
                                return "child-row-level-1";
                            }
                            return "parent-row";
                        }}
                        dataSource={careRequestsActive}
                        columns={columns}
                    />
                </Col>
            </Row>
        </>
    );
};

export default AdverseEvents;

